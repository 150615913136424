import Router from 'next/router';
import React, { useCallback, useMemo, useState } from 'react';

import { JOB_TITLE_PATHNAME } from '@glass/common/modules/pages/paths';
import { BASE_PATH_1_QUERY, BASE_PATH_3_QUERY } from '@glass/common/modules/pages/queryParams';
import ControlledSearchAutocomplete, {
  ControlledSearchAutocompleteProps,
} from '@glass/web/components/Autocomplete/ControlledSearchAutocomplete';
import { InputProps } from '@glass/web/components/base/Input';
import InputAdornment from '@glass/web/components/base/InputAdornment';
import SearchRounded from '@glass/web/components/base/icons/SearchRounded';
import { TrackingType } from '@glass/web/modules/tracking/types';
import type Suggestion from '@glass/web/modules/types/SuggestionInterface';

import useBrowseBasePath from '@glass/shared/components/Link/useBrowseBasePath';
import useResumeWords from '@glass/shared/modules/content/staticVariations/resumeWords/useResumeWords';
import getJobTitleLinkParams from '@glass/shared/modules/jobTitles/getJobTitleLinkParams';
import searchJobTitlesQuery from '@glass/shared/modules/search/searchJobTitles.graphql';
import Tracking from '@glass/shared/modules/tracking/Tracking';
import { SEARCH_JOB_TITLES_API_METHOD } from '@glass/shared/modules/tracking/constants/apiMethods';
import useTracking from '@glass/shared/modules/tracking/useTracking';

interface SearchBarProps<T extends Suggestion> {
  placeHolder?: string;
  onSearchSuccess?: () => void;
  value: ControlledSearchAutocompleteProps<T>['value'];
  textFieldClassName?: ControlledSearchAutocompleteProps<T>['textFieldClassName'];
  textFieldProps: ControlledSearchAutocompleteProps<T>['textFieldProps'];
  onChange?: ControlledSearchAutocompleteProps<T>['onChange'];
  onBlur?: ControlledSearchAutocompleteProps<T>['onBlur'];
  onFocus?: ControlledSearchAutocompleteProps<T>['onFocus'];
  autoFocus?: ControlledSearchAutocompleteProps<T>['autoFocus'];
}

const NO_SLUG_MESSAGE = 'no job title returned';

function SearchBar<T extends Suggestion>({
  value,
  placeHolder,
  textFieldProps,
  textFieldClassName,
  onSearchSuccess,
  onChange,
  onBlur,
  onFocus,
  autoFocus,
}: SearchBarProps<T>) {
  const [isLoading, setLoading] = useState(false);
  const tracking = useTracking() as TrackingType;
  const basePath1 = useBrowseBasePath() as string;

  const handleSelectChange = useCallback(
    (jobTitle: Suggestion) => {
      if (!jobTitle?.slug) {
        tracking.search({
          action: Tracking.ACTIONS.FAIL,
          message: NO_SLUG_MESSAGE, // deprecated until 6/2022
          label: `${jobTitle?.name}-${NO_SLUG_MESSAGE}`,
        });
        return null;
      }
      tracking.search({
        jobTitle,
        action: Tracking.ACTIONS.SUCCESS,
      });
      const { slug, industries } = jobTitle;
      setLoading(true);
      return Router.push({
        pathname: JOB_TITLE_PATHNAME,
        query: getJobTitleLinkParams({
          [BASE_PATH_1_QUERY]: basePath1,
          [BASE_PATH_3_QUERY]: slug,
          industries,
        }),
      })
        .then(() => {
          setLoading(false);
          onSearchSuccess?.();
        })
        .catch((err: Error) => {
          setLoading(false);
          tracking.search({
            action: Tracking.ACTIONS.FAIL,
            message: err.message, // deprecated until 6/2022
            label: err.message,
          });
        });
    },
    [basePath1, onSearchSuccess, tracking],
  );

  // TODO: Typescript-check refactor useResumeWords
  const resumeWords = useResumeWords() as { plural: string };
  const asyncTextFieldProps = useMemo<InputProps>(
    () => ({
      inputProps: {
        id: textFieldProps.id || 'search',
        'aria-label': textFieldProps['aria-label'] || 'Search',
        inputMode: textFieldProps.inputMode || 'search',
      },
      fullWidth: textFieldProps?.fullWidth,
      disableUnderline: true,
      placeholder: placeHolder || `Search ${resumeWords.plural}`,
      startAdornment: (
        <InputAdornment position="start">
          <SearchRounded />
        </InputAdornment>
      ),
    }),
    [placeHolder, resumeWords.plural, textFieldProps],
  );

  return (
    <ControlledSearchAutocomplete
      autoFocus={autoFocus}
      dataKey={SEARCH_JOB_TITLES_API_METHOD}
      disabled={isLoading}
      loading={isLoading}
      query={searchJobTitlesQuery}
      textFieldClassName={textFieldClassName}
      textFieldProps={asyncTextFieldProps}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onSelectChange={handleSelectChange}
    />
  );
}

SearchBar.displayName = 'SearchBar';

export default SearchBar;
